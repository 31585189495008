<template>
  <q-layout
    view="hHh lpR fFf"
    style="position: fixed; top: 0px; left: 0px; width: 100%; height:100%; z-index: 100;"
  >
    <!-- <q-dialog v-model="views" maximized> -->
    <q-drawer show-if-above v-model="left" side="left" bordered>
      <div class="row justify-between q-pa-md text-left bg-grey-2">
        <div>
          <div>Dashboard</div>
          <div class="text-h5">SMP AFBS</div>
        </div>
        <div>
          <q-btn
            dense
            flat
            style="width: 60px;"
            label="logout"
            no-caps
            color="negative"
            @click="logout"
          />
        </div>
      </div>
      <q-list>
        <q-item
          clickable
          v-ripple
          :active="link === 'data-pendaftar'"
          @click="onClick('data-pendaftar')"
          active-class="data-pendaftar"
        >
          <q-item-section>Data Pendaftar</q-item-section>
        </q-item>
      </q-list>
    </q-drawer>
    <q-page-container class="bg-white full-width">
      <q-scroll-area style="height:100vh;">
        <router-view />
      </q-scroll-area>
    </q-page-container>

    <!-- </q-dialog> -->
  </q-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data () {
    return {
      left: true,
      link: 'data-pendaftar',
      confirm: false,
      views: true
    }
  },
  async mounted(){
    await this.getCurrentUser()
    if(!this.$store.state.currentUser){
      this.$router.push('/admin-login')
    }
    else if(this.$store.state.currentUser.role != 'admin'){
      this.$router.push('/')
      }
    else{
        this.$q.notify({
          color: "positive",
          message: "Anda login sebagai admin",
        });
    }

  },

  methods:{
    ...mapActions(["getCurrentUser"]),
    onClick(val){
      this.link = val
      this.$router.push({name: val}).catch(() => {});
    },
    logout(){
      localStorage.removeItem("smp-afbs");
      this.$router.push('/')
      this.$router.go();
    }
  }
}
</script>
<style lang="scss" scoped>
.my-menu-link {
  color: white;
  background-color: #048eff;
}
</style>